export default function COURSE_DAY() {
  return {
    id: null,
    name: '',
    date: null,
    max_students: null,
    notes: '',
    meeting_space: {
      address: '',
      city: '',
      id: null,
      main_city: '',
      name: '',
      postal_code: '',
      route_link: '',
      textToShow: ''
    },
    city: null,
    course_day_sections: []
  };
}

export const COURSE_DAY_API_ATTR = {
  name: 'name',
  date: 'date',
  max_students: 'max_students',
  notes: 'notes',
  meeting_space: 'meeting_space',
  city: 'city',
  course_day_sections: 'course_day_sections'
};
