<template lang="pug">
  crm-dialog(
    :title="title"
    :value="showing"
    @input="changeShowing"
  )
    template(#dialog-action)
      slot(name="activator" :toggle="toggle")
        v-btn(icon :color="editable ? 'white': 'primary'" x-small @click="toggle")
          v-icon(size="22" v-if="!editable" color="orange darken-3") mdi-plus
          v-icon(size="22" v-if="editable" color="blue darken-3") mdi-pencil-circle
    template(#dialog-body)
      courseConfigurationBody(
        :key="showing"
        :courseItem="formData"
        :transportTypes="transportTypes"
        :formErrors="formErrors"
        :editable="editable"
        :crmCourseLocations="crmCourseLocations"
        :meetingSpacesList="meetingSpacesList"
        :name.sync="formData.name"
        @update:city="selectCityAndLoadMeetingSpaces"
        :notes.sync="formData.notes"
        :max_students.sync="formData.max_students"
        :date.sync="formData.date"
        @update:meeting_space="autoFillMeetingSpace"
        @update:session="addSessionConfig"
        @remove:item="removeSession"
      )
    template(#dialog-footer)
      confirmation-dialog(
        v-model="removeModal"
        @okBtnClicked="remove"
        v-if="editable"
        width="420px"
        title='Removing course day'
        descr='Are you sure you want to remove course day?'
      )
      v-btn(color="error" icon @click="removeModal = true" v-if="editable")
        v-icon mdi-delete
      v-spacer

      app-button(color="#f4f7f9" hide-shadow @click.native="clear").mr-3
        span.config-cancel Cancel
      course-day-submit(
        :btn-text="btnApply"
        :editable="editable"
        :candidates-count="candidatesCount"
        :form-errors="formErrors"
        :form-data="formData"
        @submit="submit"
      )
</template>

<script>
import {TRANSPORT_TYPE_LIST} from '../../../core/cm-const'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from "@/util/form-errors"
import {mapGetters, mapActions} from 'vuex'
import BASE_COURSE_DAY, { COURSE_DAY_API_ATTR } from '../../../core/baseCourseDay'
import api from '@/services/api/api-courses'
import CourseService from '@/app/admin/models/courseService'
import { DeleteMS } from '@/util/filters'

export default {
  name: 'courseDayCRUD',

  mixins: [errorsMixin, showingMixin],

  props: {
    editable: Boolean,
    item: Object,
    courseList: Array,
  },

  data: () => ({
    formData: BASE_COURSE_DAY(),
    transportTypes: TRANSPORT_TYPE_LIST,
    formErrors: new FormErrors(),
    loading: false,
    removeModal: false
  }),

  computed: {
    ...mapGetters({
      crmCourseLocations: 'crmCourseCities/items',
      meetingSpacesList: 'crmMeetingsSpaces/items'
    }),

    disabledAction() {
      return !!this.disabled;
    },

    title() {
      return this.editable ? 'Edit course' : 'Create course'
    },

    btnApply() {
      return this.editable ? 'Save' : 'Create'
    },

    candidatesCount() {
      if (!this.editable) return 0
      return this.item.candidates_count
    }
  },

  methods: {
    ...mapActions({
      loadCourseLocation: 'crmCourseCities/list',
      loadExamLocation: 'crmExamLocation/list',
      loadMeetingSpaces: 'crmMeetingsSpaces/list',
      loadSessions: 'sessions/list',
      loadedCourseLeaders: 'crmCourseLeaders/leadersList'
    }),

    async loadData() {
      let [sessions] = await Promise.allSettled([
        this.loadSessions({cache: true}),
        this.loadCourseLocation({cache: true}),
        this.loadExamLocation({cache: true}),
        this.loadedCourseLeaders({cache: true})
      ])
      if (!this.editable) this.addDefaultSessions(sessions.value)
    },

    addDefaultSessions(sessions) {
      sessions.forEach(session => this.formData.course_day_sections.push({
        leader: null,
        session: session
      }))
    },

    changeShowing(isShowing) {
      this.showing = isShowing
      if(!isShowing) this.resetFormData()
    },

    clear() {
      this.toggle()
      this.resetFormData()
    },

    addSessionConfig() {
      this.formData.course_day_sections.push({
        leader: null,
        session: null
      })
    },

    removeSession(index) {
      this.formData.course_day_sections.splice(index, 1);
    },

    updateSessionConfig(index, field, val) {
      this.$set(this.formData.course_day_sections[index], field, val)
    },

    async submit() {
      try {
        if(!this.editable) await this.send()
        else await this.update()
        this.clear()
      } catch (e) {
        console.log(e)
        this.processErrorWithForm(e, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: COURSE_DAY_API_ATTR
        });
      }
    },

    async send() {
      try {
        await new CourseService(api).createCourseDay({...this.formData, meeting_space: this.formData.meeting_space.id })
        this.$notify({text: 'Success', type: 'success'})
        this.$emit('create')
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async update() {
      let meetingSpaceID = this.formData.meeting_space.id
      if (meetingSpaceID === -1) meetingSpaceID = null

      try {
        let matchedSections = this.formData.course_day_sections.map(section => {
          if (section.id) return section
          let sectionWithSameSession = this.item.course_day_sections.find(primarySection => primarySection.session.id === section.session.id)
          if (!sectionWithSameSession) return section
          return {
            ...section,
            id: sectionWithSameSession.id
          }
        })
        delete this.formData.course_photo
        let res = await new CourseService(api).updateCourseDay(this.item.id, {...this.formData, meeting_space: meetingSpaceID, course_day_sections: matchedSections })
        this.$notify({text: 'Success', type: 'success'})
        this.$emit('update', {id: this.item.id, data: {...res, meeting_space: this.formData.meeting_space, course_day_sections: this.formData.course_day_sections}})
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async remove() {
      try {
        this.loading = true
        await new CourseService(api).removeCourseDay(this.item.id)
        this.toggle()
        this.$emit('click:remove')
        this.$notify({text: 'Course removed', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    // reset fields
    async resetFormData() {
      this.formData = BASE_COURSE_DAY()
      this.$store.commit('crmMeetingsSpaces/clearItems')
      this.formErrors.resetFields()
    },

    async selectCityAndLoadMeetingSpaces(payload) {
      this.formData.city = payload
      await this.loadMeetingSpaces({main_city: payload})
    },

    autoFillMeetingSpace(location) {
      this.formData.meeting_space = location
    },

    setEditableData() {
      this.formData = {
        ...JSON.parse(JSON.stringify(this.item)),
      }
    }
  },

  watch: {
    showing(isShowing) {
      if (isShowing) {
        this.loadData()
        if(this.editable) {
          this.setEditableData()
        }
        this.$emit('crudOpen')
      }
      if (!isShowing) {
        this.resetFormData()
      }
    }
  },

  provide: function() {
    return {
      addSessionConfig: this.addSessionConfig,
      removeSession: this.removeSession,
      updateSessionConfig: this.updateSessionConfig,
      updateTransportField: this.updateTransportField,
      addTransportConfig: this.addTransportConfig,
      removeTransportConfig: this.removeTransportConfig
    }
  },

  components: {
    crmDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    courseConfigurationBody: () => import('./CourseDayCRUDBody.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    courseDaySubmit: () => import('./CourseDayCrudSubmit.vue')
  }
}
</script>

<style lang="scss">
.config-cancel {
  color: $label-color;
}

</style>
